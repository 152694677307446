import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ElectricTransmission = () => (
  <Layout>
    <SEO title="Electric Transmission" />

    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Electric Transmission</h1>
        <p>
          Gray Hawk Land Solutions Transmission agents work with affected
          shareholders through each step of the process on all transmission
          projects.
        </p>
        <p>
          Gray Hawk Land Solutions works with shareholders during each step in
          the process. Gray Hawk Land Solutions attempts to balance landowner
          concerns and preferences with the need for electric infrastructure
          when locating, re-locating or acquiring rights-of-way.
        </p>
        <p>
          Gray Hawk Land Solutions discusses with shareholders easement rights
          and project specifics, including:
        </p>
        <div className="row justify-content-center my-4">
          <div className="col-md-6 col-lg-5">
            <ul>
              <li>Clearing and construction practices</li>
              <li>Post-construction maintenance</li>
              <li>Project schedule</li>
              <li>Right-of-way access</li>
              <li>The height and design of the structures</li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-5">
            <ul>
              <li>The length and width of the right-of-way</li>
              <li>The number and placement of structures</li>
              <li>Vegetation management</li>
              <li>Voltage of the power line</li>
            </ul>
          </div>
        </div>
        <p>
          Gray Hawk Land Solutions also negotiates for an amicable solution in
          cases of crop damage and/or physical damage to property resulting from
          the construction and/or maintenance of the transmission line.
        </p>

        <hr className="my-5" />

        <p className="font-weight-bold">
          What does it mean if there is an easement on your property?
        </p>
        <p>
          Any item or activity that could interfere with the safe, reliable
          operation of transmission facilities is called an encroachment and is
          prohibited under the terms of a right-of-way agreement. GRAY HAWK LAND
          SOLUTIONS will work to defend the easements that have been purchased
          by our clients to ensure reliable operation of its transmission
          systems.
        </p>
        <p>
          Gray Hawk Land Solutions will work to establish a solid relationship
          with any shareholder at any property where there is an encroachment or
          where prohibited activities are occurring at the express direction of
          our client. Gray Hawk Land Solutions may also work to require a
          shareholder to remove an encroachment at the owner’s expense.
        </p>
        <p>
          Examples of prohibited easement encroachments include but are not
          limited to:
        </p>
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-5">
            <ul>
              <li>Barns, sheds, garages</li>
              <li>Billboards</li>
              <li>Buildings, homes, porches, additions, and decks</li>
              <li>Certain types of trees and other vegetation</li>
              <li>
                Fences that deny our clients access to the easement corridor
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-5">
            <ul>
              <li>Fuel storage facilities, above ground or below grade</li>
              <li>Lights, flag poles and antennas</li>
              <li>Retention ponds</li>
              <li>Swimming pools located above and below ground</li>
              <li>Swing sets, slides and climbing equipment</li>
              <li>Uses of easement area for fill dirt or other refuse</li>
            </ul>
          </div>
        </div>
        <p>Examples of prohibited activities in an easement include:</p>
        <div className="row">
          <div className="col-md-6 col-lg-5 offset-lg-1">
            <ul>
              <li>Burning wood or refuse within the right-of-way</li>
              <li>Changing the ground elevation</li>
              <li>
                Excavating near utility poles, towers, and other facilities
              </li>
              <li>
                Operating machinery in a manner that has the potential to
                contact or damage our client’s facilities
              </li>
            </ul>
          </div>
        </div>

        <hr className="my-5" />

        <p className="font-weight-bold">
          What is allowed in the right-of-way easement?
        </p>
        <p>
          Landowners may utilize property in an easement for a variety of uses
          including;
        </p>
        <div className="row">
          <div className="col-md-6 col-lg-5 offset-lg-1">
            <ul>
              <li>Farming</li>
              <li>
                Other uses that do not interfere with the safe operation of
                electric facilities
              </li>
              <li>Yards and gardens</li>
            </ul>
          </div>
        </div>

        <hr className="my-5" />

        <p>
          Gray Hawk Land Solutions works diligently so that our clients can make
          every effort to avoid exercising their right of eminent domain on its
          customers.
        </p>
        <p>
          Gray Hawk Land Solutions acquires necessary easements through diligent
          negotiations and by working with shareholders as long as is practical,
          to reach a voluntary agreement. It is only when a voluntary agreement
          cannot be reached, and other viable alternatives do not exist, that
          the final option of exercising the right of eminent domain is pursued
          and our agents provide all supporting documentation to prove that all
          good faith efforts were extolled during the negotiation process. All
          interactions, documents, notes and phone calls are meticulously logged
          & recorded for future reference in our geoAMPS platform and turned
          over to our client’s counsel.
        </p>
        <p>
          Gray Hawk Land Solutions’ use of eminent domain authority is the
          exception rather than the rule when negotiating on behalf of
          electrical utility clients. Ninety-Seven percent of easement
          negotiations we conduct are successful.
        </p>
      </div>
    </div>
  </Layout>
)

export default ElectricTransmission
